import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const LinearIndeterminate: React.FC = () => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={9999}
      bgcolor={'white'}
    >
      <Box sx={{ width: '10%', textAlign: 'center', minWidth: '150px' }}>
        <Typography fontWeight={'bold'} letterSpacing={0.2}>
          Loading
        </Typography>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default LinearIndeterminate;
