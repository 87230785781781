import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, Typography, Card, Icon } from '@mui/material';
import { Link } from 'react-router-dom';
import ButtonTabPanel from '../components/ButtonTabPanel';
import VenueScheduleItem from '../components/VenueScheduleItem';
import TabSelector from '../components/TabSelector';
import Image from 'mui-image';
import vanue_location from '../assets/images/vanue_location.png';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from '../components/RenderedContent';
import { IVenue } from '../api/interfaces/EventFullDetailsInterface';

const tabArray = [
  { index: 0, label: 'Day 1', icon: 'brightness_1' },
  { index: 1, label: 'Day 2', icon: 'brightness_1' },
  { index: 2, label: 'Day 3', icon: 'brightness_1' },
];

const iconStyle = {
  color: 'secondary',
  fontSize: '12px',
  marginRight: '5px',
};

const VenueTab: React.FC = () => {
  const { eventFullDetailsData, eventFullDetailsLoading, error } = useSelector(
    (state: RootState) => state.api
  );

  const { venue } = useSelector((state: RootState) => state.tabLocation);

  const registrationUrl = eventFullDetailsData?.data
    ?.registration_link as string;
  const locations = eventFullDetailsData?.data?.locations;
  const eventDateAndTime =
    locations && locations[venue] && locations[venue]?.event_date_time;
  const venues = (locations &&
    locations[venue] &&
    locations[venue]?.venues) as IVenue[];

  const [value, setValue] = useState(0);

  return (
    <Grid container direction={'row'} justifyContent={'center'}>
      <Grid item maxWidth={'554px'} flexGrow={1} m={1.5}>
        <Card sx={{ padding: '2.875rem' }}>
          <Typography fontSize={'1.75rem'} fontWeight={'bold'}>
            Date & Time
          </Typography>
          <RenderedContent>
            <Typography fontSize={'1.25rem'}>{eventDateAndTime}</Typography>
          </RenderedContent>

          <Box marginTop={'36px'}>
            <Box marginY={'36px'}>
              <TabSelector value={value} onChange={setValue} tabs={venues}>
                {venues?.map((_, index) => (
                  <Box key={index} display={'flex'} alignItems={'center'}>
                    <Icon style={iconStyle}>{'brightness_1'}</Icon>
                    <Typography fontWeight={'bold'} textTransform={'none'}>
                      {`Day ${index + 1}`}
                    </Typography>
                  </Box>
                ))}
              </TabSelector>
            </Box>
            {venues?.map((vanue, index) => (
              <ButtonTabPanel key={index} value={value} index={index}>
                <VenueScheduleItem
                  schedule={vanue?.starting_date_time}
                  title={vanue?.agenda_title}
                  icon="schedule"
                />
                <VenueScheduleItem
                  schedule={vanue?.agenda_schedule}
                  title={vanue?.agenda_topics}
                  icon="receipt_long"
                />
                <VenueScheduleItem
                  schedule={vanue?.agenda_break_time}
                  title={vanue?.agenda_break_title}
                  icon="schedule"
                />
              </ButtonTabPanel>
            ))}
          </Box>
          <Box>
          {
            !(registrationUrl == '') && <Button
              variant="contained"
              component={Link}
              disabled={!registrationUrl?.trim()}
              color="secondary"
              className=""              
              sx={{
                color: 'white',
                marginTop: '36px',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                },
              }}
              to={registrationUrl}
            >
              Register Now
            </Button>
          }
          </Box>
        </Card>
      </Grid>

      <Grid item maxWidth={'554px'} textAlign={'center'} flexGrow={1} m={1.5}>
        {venues?.[value]?.show_map_or_picture?.[0] === "image" ? (
          <Image
            duration={600}
            src={venues?.[value]?.venue_picture || ""}
            height={342}
            style={{ borderRadius: 10 }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: venues?.[value]?.venue_embedded_map || "" }}>
          </div>
        )}


        <Typography
          fontSize={'1.3rem'}
          color="#333131"
          fontFamily={'DM Serif Display'}
          fontWeight={'bold'}
          marginTop={'36px'}
          marginBottom={'25px'}
          lineHeight={'1.5rem'}
        >
          Address:
        </Typography>
        <RenderedContent>
          <Typography
            fontSize={'1.12rem'}
            fontFamily={'Red Hat Display'}
            fontWeight={'bold'}
            color="#016698"
          >
            {venues?.[value]?.venue_name}
          </Typography>
        </RenderedContent>
        <RenderedContent>
          <Typography fontSize={'1.125rem'} fontFamily={'Red Hat Display'}>
            {venues?.[value]?.venue_address}
          </Typography>
        </RenderedContent>
      </Grid>
    </Grid>
  );
};

export default VenueTab;
