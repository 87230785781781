import React from 'react';
import { Box } from '@mui/material';

interface QuarterCircleProps {
  bcolor: string;
  angle: number;
  size: number;
  transformOrigin?: string;
}

const QuarterCircle: React.FC<QuarterCircleProps> = ({
  bcolor = '#48abe0',
  angle = 0,
  size = 10,
  transformOrigin = 'middle',
}) => {
  const borderRadius = `0 ${size}rem 0 0`;
  const transform = `rotate(${-1 * angle}deg)`;

  return (
    <Box
      sx={{
        width: `${size}rem`,
        height: `${size}rem`,
        backgroundColor: bcolor,
        borderRadius: borderRadius,
        transform: transform,
        transformOrigin: transformOrigin,
      }}
    ></Box>
  );
};

export default QuarterCircle;
