import React from 'react';
import { Box, Typography } from '@mui/material';

interface InvalidRouteProps {
  title?: string;
  description?: string;
}

const InvalidRoute: React.FC<InvalidRouteProps> = ({
  title = '404 - Not Found',
  description = "The page you're looking for does not exist.",
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="60vh"
    >
      <Typography variant="h4" component="h1" align="center">
        {title}
      </Typography>
      <Typography variant="body1" align="center">
        {description}
      </Typography>
    </Box>
  );
};

export default InvalidRoute;
