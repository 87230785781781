import { Theme, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#276D9E',
    },
    secondary: {
      main: '#FDAF31',
    },
    info: {
      main: '#646464',
    },
  },
  typography: {
    button: {
      fontSize: '1.25rem',
      // fontWeight: 'regular',
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 850,
      lg: 1300,
      xl: 1536,
    },
  },
}); // Remove hover color type issue

(theme as Theme).components = {
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingTop: '4.75rem',
        paddingBottom: '4.75rem',
        paddingLeft: 0,
        paddingRight: 0,
        [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
          paddingTop: '4rem',
          paddingBottom: '4rem',
        },
        [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
          paddingTop: '3rem',
          paddingBottom: '3rem',
        },
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          paddingTop: '2rem',
          paddingBottom: '2rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
      },
    },
  },
};

export default theme;
