export interface IEvent {
  id: number;
  title: string;
  description: object;
  image: string;
  date: string;
}

function getRendered(des: any) {
  if (typeof des === 'string') {
    return des;
  } else if (typeof des === 'object' && des.hasOwnProperty('rendered')) {
    return des.rendered;
  } else {
    return '';
  }
}

function convertToDate(dateString: any): Date | null {
  let date: Date | null = null;

  if (dateString.includes('/')) {
    const parts = dateString.split('/');
    const month = parseInt(parts[0]) - 1;
    const day = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    date = new Date(year, month, day);
  } else if (dateString.includes('-')) {
    date = new Date(dateString);
  }

  return date;
}

export class EventType {
  id: number;
  title: string;
  description: string;
  image: string;
  date: Date;
  constructor(data: any) {
    this.id = Number(data?.id);
    this.title = getRendered(data?.title);
    this.description = getRendered(data?.excerpt || data?.content);
    this.image = data?.featured_image;
    this.date = convertToDate(data?.event_date || data?.date_gmt) as Date;
  }
}
