import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../redux/store';
import { IFooterRenderedResponse } from '../api/interfaces/FooterContentInterface';
import RenderedContent from '../components/RenderedContent';
import { Container, Box } from '@mui/material';

const RenderedPage: React.FC = () => {
  const location = useLocation();
  const pathName = location.pathname.substring(1);

  const { footerContentData } = useSelector((state: RootState) => state.api);

  const footerData = footerContentData?.data as IFooterRenderedResponse['data'];
  const content = footerData && footerData[pathName];

  return (
    <Box className='footer-common-box'>
      <Box className="custom-container">
        <RenderedContent>
          {content}
        </RenderedContent>
      </Box>
    </Box>
  );
};

export default RenderedPage;
