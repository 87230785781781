import {
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@mui/material';
import React, { ReactNode } from 'react';
import theme from '../theme/basic';
import { CheckCircleOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from './RenderedContent';
import Arrows from '../assets/images/arrows.svg';
import Dots from '../assets/images/dots.svg';

interface ListItmProps {
  icon: ReactNode;
  text: string;
}

const listItemStyles = {
  color: 'white',
  padding: '0px',
  margin: '0px',
  fontSize: '1.25rem',
};

const listIconStyles = {
  color: 'white',
  minWidth: 0,
  paddingRight: '0.75rem',
};

const ListItm: React.FC<ListItmProps> = ({ icon, text }) => {
  return (
    <ListItem style={{ padding: 0 }}>
      <ListItemIcon style={listIconStyles}>{icon}</ListItemIcon>
      <ListItemText>
        <Typography variant="body1" style={{ fontSize: '1.25rem' }}>
          {text}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};

const StandoutCard: React.FC = () => {
  const { eventFullDetailsData, eventFullDetailsLoading, error } = useSelector(
    (state: RootState) => state.api
  );

  const { size } = useSelector((state: RootState) => state.mquery);

  const registrationUrl = eventFullDetailsData?.data
    ?.registration_link as string;
  const standoutAdvantages = eventFullDetailsData?.data?.standouts
    ?.contents as string;
  const standoutBgColor = eventFullDetailsData?.data?.standouts
    ?.background_color as string;

    

  return (
    <Card className='standout-box'
      sx={{
        bgcolor: standoutBgColor,
        padding: '3rem',
        marginTop: '3rem',
        position: 'relative',
      }}
    >
      {!(size === 'sm' || size === 'xs') && (
        <img
          src={Arrows}
          alt="SVG arrow"
          width="15%"
          style={{ position: 'absolute', bottom: '50px', left: 0 }}
        />
      )}
      {!(size === 'sm' || size === 'xs') && (
        <img
          src={Dots}
          alt="SVG dots"
          width="8%"
          style={{ position: 'absolute', top: 0, right: 0 }}
        />
      )}
      <Grid container>
        <Grid item md={3}>
          <Typography
            color={'white'}
            fontSize={'1.8rem'}
            fontWeight={'bold'}
            lineHeight={1}
          >
            Standouts
          </Typography>
        </Grid>
        <Grid item md={9} pl={3}>
          <RenderedContent>{standoutAdvantages}</RenderedContent>
          <Grid className="">
          {
            !(registrationUrl == '') && <Button            
            variant="contained"
            color="secondary"
            to={registrationUrl}
            disabled={!registrationUrl?.trim()}
            component={Link}
            sx={{
              color: 'white',
              marginTop: '2.5rem',
              '&:hover': {
                backgroundColor: 'secondary.main',
              },
            }}
          >
            Register Now
          </Button>
          }
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default StandoutCard;
