import React, { FC } from 'react';
import Typography from '@mui/material/Typography';

interface EllipsisTextProps {
  text: string;
  lineCount: number;
  sx?: object;
}

const EllipsisText: FC<EllipsisTextProps> = ({ text, lineCount, sx }) => {
  const containerStyle: React.CSSProperties = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lineCount,
  };

  const isHTML = /<[a-z][\s\S]*>/i.test(text);

  return isHTML ? (
    <Typography
      component="div"
      sx={{ ...sx }}
      style={containerStyle}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <Typography style={containerStyle} sx={{ ...sx }}>
      {text}
    </Typography>
  );
};

export default EllipsisText;
