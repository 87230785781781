import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counter';
import apiReducer from './apiSlice';
import tabLocationReducer from './tabSlice';
import mqueryReducer from './mquerySlice';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    api: apiReducer,
    mquery: mqueryReducer,
    tabLocation: tabLocationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
