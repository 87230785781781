import React from 'react';
import { Box, Card, Grid, Typography, useMediaQuery } from '@mui/material';
import Image from 'mui-image';

import { IAgenda } from '../api/interfaces/EventFullDetailsInterface';
import RenderedContent from './RenderedContent';
import theme from '../theme/basic';

interface AgendaScheduleItemProps {
  event: IAgenda;
}

const AgendaScheduleItem: React.FC<AgendaScheduleItemProps> = ({ event }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card sx={{ marginBottom: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Box
          sx={{
            minWidth: isMobile ? '100%' : '220px',
            maxWidth: isMobile ? '100%' : '220px',
          }}
          bgcolor={'#f7f7f7'}
        >
          <Box p={'1rem'} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <RenderedContent>
                <Typography fontWeight={'bold'} fontSize={'1rem'}>
                  {event?.schedule}
                </Typography>
              </RenderedContent>
              <Grid container direction={'row'} wrap="nowrap" mt={'0.5rem'}>
                {event?.presentor_picture && (
                  <Grid item>
                    <Image
                      src={event?.presentor_picture}
                      style={{ borderRadius: '50%' }}
                      width={50}
                      height={50}
                      duration={600}
                    />
                  </Grid>
                )}
                <Grid item mx={'1rem'} display={'flex'} alignItems={'center'}>
                  <RenderedContent>
                    <Typography
                      fontWeight={'bold'}
                      fontSize={'0.75rem'}
                      color={'primary'}
                    >
                      {event.presentor}
                    </Typography>
                  </RenderedContent>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box
          marginLeft={'0px'}
          sx={{ display: 'flex', alignItems: 'flex-start' }}
        >
          <Box pl={'1rem'} pt={'1rem'} pr={'1rem'}>
            <RenderedContent>
              <Typography
                fontSize={'1.125rem'}
                lineHeight={'2rem'}
                fontWeight={'bold'}
                color={'black'}
              >
                {event?.topics}
              </Typography>
            </RenderedContent>
            {event?.description && (
              <RenderedContent>
                <Typography fontSize={'1.1255rem'} color={'gray'}>
                  {event?.description}
                </Typography>
              </RenderedContent>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default AgendaScheduleItem;
