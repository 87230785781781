import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import theme from '../theme/basic';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Header from '../components/Header';
import EventCard from '../components/EventCard';
import { stripHtml } from '../util/html';

const themeColor = '#FDAF31';

const EventsPage: React.FC = () => {
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { allEventsData, allEventsLoading } = useSelector(
    (state: RootState) => state.api
  );

  return (
    <Container
      disableGutters
      fixed
      style={{
        overflowX: 'clip',
        overflowY: 'initial',
      }}
    >
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Header
            title={'All Events'}
            sx={{ marginBottom: '3.375rem' }}
            leftBorderColor={themeColor}
          />
        </Grid>
        <Box
          display="flex"
          justifyContent={isMdScreen ? 'center' : 'space-between'}
          flexWrap="wrap"
        >
          {(allEventsData ? allEventsData : []).map((item, index) => (
            <EventCard
              id={item?.id}
              key={index}
              sx={{ marginBottom: '1.375rem' }}
              imageSrc={item.featured_image ? item.featured_image : ''}
              topBorderColor={themeColor}
              date={new Date(item?.date_gmt)}
              title={item?.title?.rendered}
              description={stripHtml(item?.content?.rendered)}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default EventsPage;
