import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LogoText from '../components/LogoText';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import LogoImage from '../components/LogoImage';

const drawerWidth = '80vw';

const NavList: Array<NavListProps> = [
  { id: 1, to: '/', label: 'Home' },
  { id: 2, to: '/events', label: 'Events' },
  { id: 3, to: '/gallery', label: 'Gallery' },
  { id: 4, to: '/about-us', label: 'About Us' },
  { id: 5, to: '/contact-us', label: 'Contact Us' },
];

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}
interface NavListProps {
  id: number;
  to: string;
  label: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  to,
  children,
  ...props
}: NavLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to} style={{ textDecoration: 'none' }} {...props}>
      <Typography
        textTransform="none"
        variant="button"
        color={isActive ? 'primary' : 'black'}
        sx={{ fontSize: '1rem', fontWeight: 'bold' }}
      >
        {children}
      </Typography>
    </Link>
  );
};

const NavBar: React.FC = () => {
  const theme = useTheme();
  const [navbaVisible, setNavbarVisible] = useState<boolean | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);

  const { siteBasicInfoData, siteBasicInfoLoading } = useSelector(
    (state: RootState) => state.api
  );

  const logoImage = siteBasicInfoData?.data?.logo;
  const logoUrl = siteBasicInfoData?.data?.url;  

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setNavbarVisible(true);
        } else {
          setNavbarVisible(false);
        }
      });
    });

    if (navRef.current) {
      observer.observe(navRef.current);
    }

    return () => {
      if (navRef.current) {
        observer.unobserve(navRef.current);
      }
    };
  }, []);

  return (
    <div ref={navRef}>
      <div className='header-box'
        style={{
          position: navbaVisible ? 'static' : 'fixed',
          top: 0,
          width: '100%',
          zIndex: 5,
        }}
      >
        <Box sx={{ backgroundColor: 'white' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="header-inner"
          >
            <Grid className='logo-img' item pl={isMobile ? 1 : 3}>
              <LogoImage                
                imgSrc={logoImage}
                url={logoUrl}
                height={isMobile || !navbaVisible ? 22.4 : 32}
                width={isMobile || !navbaVisible ? 111.167 : 158.8}
              />
            </Grid>

            {!isMobile && (
              <Grid item flexGrow={1}>
                <Grid container justifyContent="flex-end">
                  <Toolbar
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      height: navbaVisible ? '5rem' : '3rem',
                      transition: 'all 0.3s',
                      flexBasis: '570px',
                      maxWidth: '570px',
                      textAlign: 'right',
                    }}
                  >
                    {NavList.map((item) => (
                      <NavLink key={item.id} to={item.to}>
                        {item.label}
                      </NavLink>
                    ))}
                  </Toolbar>
                </Grid>
              </Grid>
            )}

            {isMobile && (
              <Grid item>
                <IconButton onClick={toggleDrawer}>
                  <MenuRoundedIcon />
                </IconButton>
                <SwipeableDrawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={toggleDrawer}
                  onOpen={toggleDrawer}
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': { width: drawerWidth },
                  }}
                >
                  <Box sx={{ position: 'absolute' }}>
                    <Grid
                      container
                      justifyContent="center"
                      marginTop={2}
                      ml={1}
                    >
                      <Grid item>
                        <LogoText label="medi,quest" size={1.4} />
                      </Grid>
                    </Grid>
                    <List sx={{ width: '150%' }}>
                      {NavList.map((item) => (
                        <ListItemButton
                          key={item.id}
                          component={Link}
                          to={item.to}
                          onClick={toggleDrawer}
                        >
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Box>
                </SwipeableDrawer>
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default NavBar;
