import { IFooterRoute } from '../api/interfaces/FooterContentInterface';

export const footerRoutes: IFooterRoute[] = [
  {
    key: 'terms-and-conditions',
    label: 'Terms & Conditions',
    route: 'terms-and-conditions',
  },
  { key: 'privacy-policy', label: 'Privacy Policy', route: 'privacy-policy' },
  {
    key: 'cancellations-and-refunds',
    label: 'Cancellations & Refunds',
    route: 'cancellations-and-refunds',
  },
  { key: 'contact-us', label: 'Contact Us', route: 'contact-us' },
  { key: 'about-us', label: 'About Us', route: 'about-us' },
];
