import React from 'react';
import { Box } from '@mui/material';
import Image from 'mui-image';
import { Link } from 'react-router-dom';

interface LogoTextProps {
  imgSrc: string | undefined;
  height?: number;
  width?: number;
  size?: number;
  url?: string;
}

const LogoImage: React.FC<LogoTextProps> = ({
  imgSrc = '',
  size,
  height,
  width,
  url = '#',
}) => {
  const style = {
    transform: `scale(${size})`,
    WebkitTransform: `scale(${size})`,
  };
  return (
    <Box>
      <Link to={url}>
        <Image
          duration={600}
          src={imgSrc}
          height={`${height}px`}
          width={`${width}px`}
        />
      </Link>
    </Box>
  );
};

export default LogoImage;
