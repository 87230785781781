export const getCurrentYear = (): number => new Date().getFullYear();

// 29/04/2023 convert to April 29, 2023

export const formatDateEN_US = (dateString: string): string => {
  const date = formatDateObj(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  return formattedDate;
};

export const formatDateObj = (dateString: string): Date => {
  if (!dateString) {
    return new Date();
  }
  const [month, day, year] = dateString.split('/');
  const date = new Date(`${month}/${day}/${year}`);
  return date;
};
