import React from 'react';
import { Button, Grid, Link } from '@mui/material';
import { IVenue } from '../api/interfaces/EventFullDetailsInterface';

interface TabSelectorProps {
  value: number;
  onChange: (newValue: number) => void;
  tabs: any[];
  children: React.ReactNode;
}

const TabSelector: React.FC<TabSelectorProps> = ({
  value,
  onChange,
  tabs,
  children,
}) => {
  const handleTabClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    newValue: number
  ) => {
    onChange(newValue);
  };

  const childList = React.Children.toArray(children);

  return (
    <Grid container direction="row" justifyContent="space-between">
      {tabs?.map((_, index) => (
        <React.Fragment key={index}>
          <Button
            style={{
              flexGrow: 1,
              margin: '0px 5px',
              marginBottom: '10px',
              padding: '15px',
              justifyContent: 'flex-start',
            }}
            variant={value === index ? 'contained' : 'outlined'}
            color="primary"
            onClick={(e) => handleTabClick(e, index)}
          >
            {childList[index]}
          </Button>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default TabSelector;
