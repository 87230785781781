import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface MQueryState {
  size: string;
}

const initialState: MQueryState = {
  size: 'sm',
};

export const mquerySlice = createSlice({
  name: 'mquery',
  initialState,
  reducers: {
    setScreen: (state, action: PayloadAction<string>) => {
      state.size = action.payload;
    },
  },
});

export const { setScreen } = mquerySlice.actions;

export default mquerySlice.reducer;
