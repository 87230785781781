import { useDispatch } from 'react-redux';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  upcomingEventsData,
  pastEventsData,
  eventFullDetailsData,
  allEventsData,
  categoriesData,
  siteBasicInfoData,
  primaryMenuData,
  footerContentData,
  contactData,
  galleryData,
} from '../api/api';
import { IUpcomingEvent } from '../api/interfaces/UpcomingEventInterface';
import { IPastEvent } from '../api/interfaces/PastEventInterface';
import { IEventFullDetailsResponse } from '../api/interfaces/EventFullDetailsInterface';
import { IEvent } from '../api/interfaces/AllEventInterface';
import { ISiteBasicInfoResponse } from '../api/interfaces/SiteBasicInfoInterface';
import { ICategory } from '../api/interfaces/CategoryInterface';
import { AppDispatch } from './store';
import { IPrimaryMenuResponse } from '../api/interfaces/PrimaryMenuInterface';
import {
  IFooterContentResponse,
  IFooterRenderedResponse,
} from '../api/interfaces/FooterContentInterface';
import { IContact } from '../api/interfaces/ContactInterface';
import { footerRoutes } from '../constants/footer';
import { IGalleryResponse } from '../api/interfaces/galleryInterface';

export interface ApiState {
  upcomingEventsData: IUpcomingEvent[] | null;
  pastEventsData: IPastEvent[] | null;
  eventFullDetailsData: IEventFullDetailsResponse | null;
  allEventsData: IEvent[] | null;
  categoriesData: ICategory[] | null;
  siteBasicInfoData: ISiteBasicInfoResponse | null;
  primaryMenuData: IPrimaryMenuResponse | null;
  footerContentData: IFooterRenderedResponse | null;
  contactData: IContact[] | null;
  galleryData: IGalleryResponse | null;
  upcomingEventsLoading: boolean;
  pastEventsLoading: boolean;
  eventFullDetailsLoading: boolean;
  allEventsLoading: boolean;
  categoriesLoading: boolean;
  siteBasicInfoLoading: boolean;
  primaryMenuLoading: boolean;
  footerContentLoading: boolean;
  contactLoading: boolean;
  galleryLoading: boolean;
  heroImageLoaded: boolean;
  error: string | null;
}

const initialState: ApiState = {
  upcomingEventsData: null,
  pastEventsData: null,
  eventFullDetailsData: null,
  allEventsData: null,
  categoriesData: null,
  siteBasicInfoData: null,
  primaryMenuData: null,
  footerContentData: null,
  contactData: null,
  galleryData: null,
  upcomingEventsLoading: false,
  pastEventsLoading: false,
  eventFullDetailsLoading: false,
  allEventsLoading: false,
  categoriesLoading: false,
  siteBasicInfoLoading: false,
  primaryMenuLoading: false,
  footerContentLoading: false,
  contactLoading: false,
  galleryLoading: false,
  heroImageLoaded: false,
  error: null,
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setUpcomingEventsData: (state, action: PayloadAction<IUpcomingEvent[]>) => {
      state.upcomingEventsData = action.payload;
      state.upcomingEventsLoading = false;
      state.error = null;
    },
    setPastEventsData: (state, action: PayloadAction<IPastEvent[]>) => {
      state.pastEventsData = action.payload;
      state.pastEventsLoading = false;
      state.error = null;
    },
    setEventFullDetailsData: (
      state,
      action: PayloadAction<IEventFullDetailsResponse>
    ) => {
      state.eventFullDetailsData = action.payload;
      state.eventFullDetailsLoading = false;
      state.error = null;
    },
    setAllEventsData: (state, action: PayloadAction<IEvent[]>) => {
      state.allEventsData = action.payload;
      state.allEventsLoading = false;
      state.error = null;
    },
    setCategoriesData: (state, action: PayloadAction<ICategory[]>) => {
      state.categoriesData = action.payload;
      state.categoriesLoading = false;
      state.error = null;
    },
    setSiteBasicInfoData: (
      state,
      action: PayloadAction<ISiteBasicInfoResponse>
    ) => {
      state.siteBasicInfoData = action.payload;
      state.siteBasicInfoLoading = false;
      state.error = null;
    },
    setPrimaryMenuData: (
      state,
      action: PayloadAction<IPrimaryMenuResponse>
    ) => {
      state.primaryMenuData = action.payload;
      state.primaryMenuLoading = false;
      state.error = null;
    },
    setFooterContentData: (
      state,
      action: PayloadAction<IFooterRenderedResponse>
    ) => {
      state.footerContentData = action.payload;
      state.footerContentLoading = false;
      state.error = null;
    },
    setContactData: (state, action: PayloadAction<IContact[]>) => {
      state.contactData = action.payload;
      state.contactLoading = false;
      state.error = null;
    },
    setGalleryData: (state, action: PayloadAction<IGalleryResponse>) => {
      state.galleryData = action.payload;
      state.galleryLoading = false;
      state.error = null;
    },

    setUpcomingEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.upcomingEventsLoading = action.payload;
    },
    setPastEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.pastEventsLoading = action.payload;
    },
    setEventFullDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.eventFullDetailsLoading = action.payload;
    },
    setAllEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.allEventsLoading = action.payload;
    },
    setCategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.categoriesLoading = action.payload;
    },
    setSiteBasicInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.siteBasicInfoLoading = action.payload;
    },
    setPrimaryMenuLoading: (state, action: PayloadAction<boolean>) => {
      state.primaryMenuLoading = action.payload;
    },
    setFooterContentLoading: (state, action: PayloadAction<boolean>) => {
      state.footerContentLoading = action.payload;
    },
    setContactLoading: (state, action: PayloadAction<boolean>) => {
      state.contactLoading = action.payload;
    },
    setGalleryLoading: (state, action: PayloadAction<boolean>) => {
      state.contactLoading = action.payload;
    },
    setHeroImageLoaded: (state, action: PayloadAction<boolean>) => {
      state.heroImageLoaded = action.payload;
    },

    setError: (state, action: PayloadAction<any>) => {
      state.upcomingEventsLoading = false;
      state.pastEventsLoading = false;
      state.eventFullDetailsLoading = false;
      state.allEventsLoading = false;
      state.categoriesLoading = false;
      state.siteBasicInfoLoading = false;
      state.primaryMenuLoading = false;
      state.footerContentLoading = false;
      state.contactLoading = false;
      state.galleryLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setUpcomingEventsData,
  setPastEventsData,
  setEventFullDetailsData,
  setAllEventsData,
  setCategoriesData,
  setSiteBasicInfoData,
  setPrimaryMenuData,
  setFooterContentData,
  setContactData,
  setGalleryData,
  setUpcomingEventsLoading,
  setPastEventsLoading,
  setEventFullDetailsLoading,
  setAllEventsLoading,
  setCategoriesLoading,
  setSiteBasicInfoLoading,
  setPrimaryMenuLoading,
  setFooterContentLoading,
  setContactLoading,
  setGalleryLoading,
  setHeroImageLoaded,
  setError,
} = apiSlice.actions;

export default apiSlice.reducer;

export const fetchUpcomingEventsData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setUpcomingEventsLoading(true));
    try {
      const response: IUpcomingEvent[] = await upcomingEventsData();
      dispatch(setUpcomingEventsData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchPastEventsData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPastEventsLoading(true));
    try {
      const response: IPastEvent[] = await pastEventsData();
      dispatch(setPastEventsData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchEventFullDetailsData = (eventID: string | undefined): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setEventFullDetailsLoading(true));
    try {
      const response: IEventFullDetailsResponse = await eventFullDetailsData(
        eventID
      );
      dispatch(setEventFullDetailsData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchAllEventsData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setAllEventsLoading(true));
    try {
      const response: IEvent[] = await allEventsData();
      dispatch(setAllEventsData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchCategoriesData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setCategoriesLoading(true));
    try {
      const response: ICategory[] = await categoriesData();
      dispatch(setCategoriesData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchSiteBasicInfoData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSiteBasicInfoLoading(true));
    try {
      const response: ISiteBasicInfoResponse = await siteBasicInfoData();
      dispatch(setSiteBasicInfoData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchPrimaryMenuData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPrimaryMenuLoading(true));
    try {
      const response: IPrimaryMenuResponse = await primaryMenuData();
      dispatch(setPrimaryMenuData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchFooterContentoData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setFooterContentLoading(true));
    try {
      const response: IFooterContentResponse = await footerContentData();
      const reformattedData: IFooterRenderedResponse['data'] =
        response.data.reduce((acc: any, item: string, index: number) => {
          const key = footerRoutes[index]?.key;
          acc[key] = item;
          return acc;
        }, {});
      const res: IFooterRenderedResponse = {
        ...response,
        data: reformattedData,
      };
      dispatch(setFooterContentData(res));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchContactData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setContactLoading(true));
    try {
      const response: IContact[] = await contactData();
      dispatch(setContactData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};

export const fetchGalleryData = (): any => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGalleryLoading(true));
    try {
      const response: IGalleryResponse = await galleryData();
      dispatch(setGalleryData(response));
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
};
