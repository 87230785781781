import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DetailsImageSet from '../components/DetailsImageSet';
import StandoutCard from '../components/StandoutCard';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from '../components/RenderedContent';
import { json } from 'stream/consumers';

const DetailsTab: React.FC = () => {
  const [isImage, setIsImage] = useState([]);

  const { eventFullDetailsData, eventFullDetailsLoading } = useSelector(
    (state: RootState) => state.api
  );

  // React.useEffect(() => {    
  //   ccc();
  // }, []);

  // function ccc() {    
  //   const itemss = JSON.parse(localStorage.getItem('abc') || '{}');
  //   console.log(itemss);
  //   setIsImage(itemss);
  // }

  const { size } = useSelector((state: RootState) => state.mquery);

  const { content: detailsContent, event_galleries: imageArr } = eventFullDetailsData?.data as any || { detailsContent: '', event_galleries: []}
  // className={`${(isImage.length > 0) ? 'yes' : 'No' }`}

  return (
    <Grid container >
      <Grid item xs={12} md={imageArr.filter(Boolean).length ? 6 : 12}>
        <Box paddingRight={'15px'}>
          <RenderedContent>
            <Typography fontSize={'1.25rem'} mb={'1rem'} pr={5}>
              {detailsContent}
            </Typography>
          </RenderedContent>
        </Box>
      </Grid>

      {!(size == 'xs') && (
        <Grid item xs={12} md={6}>
          <DetailsImageSet />
        </Grid>
      )}
      <Grid item xs={12}>
        <StandoutCard />
      </Grid>
    </Grid>
  );
};

export default DetailsTab;
