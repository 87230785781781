import React, { ReactNode } from 'react';
import { Box, Button, Grid, SxProps, Typography } from '@mui/material';

interface HeaderProps {
  title: string;
  sx?: SxProps;
  children: React.ReactNode;
  leftBorderColor: string;
  controllers?: ReactNode;
}

type RequiredOne<T, Keys extends keyof T = keyof T> = Keys extends keyof T
  ? Required<Pick<T, Keys>> & Partial<Omit<T, Keys>>
  : never;

type PropsWithEitherTitleOrChildren = RequiredOne<
  HeaderProps,
  'title' | 'children'
>;

const Header: React.FC<PropsWithEitherTitleOrChildren> = ({
  title,
  sx,
  children,
  leftBorderColor,
  controllers,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent={'space-between'}
      sx={{ ...sx }}
    >
      <Box display={'flex'} alignItems="center">
        <Box bgcolor={leftBorderColor} width="0.3125rem" height="3.25rem"></Box>
        <Box px={2}>
          {children ? (
            children
          ) : (
            <Typography
              fontSize="1.625rem"
              fontWeight="bold"
              // mx={2}
              fontFamily={'DM Serif Display'}
            >
              {title}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>{controllers}</Box>
    </Grid>
  );
};

export default Header;
