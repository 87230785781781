import React from 'react';
import HeroSection from '../sections/HeroSection';
import EventSliderSection from '../sections/EventSliderSection';
import EventListSection from '../sections/EventListSection';
import AboutSection from '../sections/AboutSection';
import LogoText from '../components/LogoText';
import ContactSection from '../sections/ContactSection';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
  fetchAllEventsData,
  fetchCategoriesData,
  fetchPastEventsData,
  fetchUpcomingEventsData,
} from '../redux/apiSlice';
import LinearIndeterminate from '../components/LinearIndeterminate';
import { EventType } from '../api/interfaces/CommonInterfaces';
import theme from '../theme/basic';

const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    siteBasicInfoData,
    heroImageLoaded,
    pastEventsData,
    pastEventsLoading,
  } = useSelector((state: RootState) => state.api);

  const aboutMediquestEvents = siteBasicInfoData?.data?.aboutMediquestEvents;
  const aboutMediquest = siteBasicInfoData?.data?.aboutMediquest;

  React.useEffect(() => {
    dispatch(fetchUpcomingEventsData());
    dispatch(fetchPastEventsData());
    dispatch(fetchAllEventsData()); // add
    dispatch(fetchCategoriesData());
  }, []);

  return (
    <>
      {!heroImageLoaded && <LinearIndeterminate />}
      {/* Hero blue content */}
      <HeroSection />
      {/* Hero blue content */}

      <EventSliderSection title={'Upcoming Events'} themeColor={'#E14E61'} />

      <EventListSection
        title={'Past Events'}
        themeColor={'#FDAF31'}
        events={
          pastEventsData
            ?.map((event, index) =>
              isMobile && index > 2 ? null : new EventType(event)
            )
            .filter(Boolean) as EventType[]
        }
        eventsLoading={pastEventsLoading}
        route={'/events'}
      />

      <AboutSection 
        bgColor="primary.main"
        description={aboutMediquestEvents}
        color="white"
      >
        
        <Grid container direction="row" justifyContent="flex-start">
          <Typography
            color={'white'}
            fontSize={'1.550rem'}
            fontWeight={600}
            letterSpacing={1.56}
            fontFamily={'DM Serif Display'}
          >
            About&nbsp;
          </Typography>
          <Grid item>
            <LogoText
              label="Medi,quest&nbsp;"
              size={1.55}
              weight="bold"
              letterSpacing={1.56}
              colors={['white', 'secondary']}
            />
          </Grid>
          <Typography
            color={'white'}
            fontSize={'1.550rem'}
            fontWeight={600}
            letterSpacing={1.56}
            fontFamily={'DM Serif Display'}
          >
            Events
          </Typography>
        </Grid>
      </AboutSection>

      <AboutSection bgColor="white" description={aboutMediquest}>
        <Grid container direction="row" justifyContent="flex-start">
          <Typography
            color={'primary.main'}
            fontSize={'1.550rem'}
            fontWeight={600}
            letterSpacing={1.56}
            fontFamily={'DM Serif Display'}
          >
            About&nbsp;
          </Typography>
          <Grid item>
            <LogoText
              label="Medi,quest"
              size={1.55}
              weight="bold"
              letterSpacing={1.56}
              colors={['primary', 'secondary']}
            />
          </Grid>
        </Grid>
      </AboutSection>

      <ContactSection />
    </>
  );
};

export default HomePage;
