import React, { useRef, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import Image from 'mui-image';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from '../components/RenderedContent';

const ContactSection: React.FC = () => {
  const { siteBasicInfoData, siteBasicInfoLoading } = useSelector(
    (state: RootState) => state.api
  );
  const leftContainerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [leftContainerHeight, setLeftContainerHeight] = useState<number>(0);
  const { size } = useSelector((state: RootState) => state.mquery);

  React.useEffect(() => {
    if (leftContainerRef.current) {
      setLeftContainerHeight(leftContainerRef.current.clientHeight);
    }
  }, []);

  const handleImageLoad = () => {
    if (leftContainerRef.current) {
      setLeftContainerHeight(leftContainerRef.current.clientHeight);
    }
  };

  const title = siteBasicInfoData?.data?.contactUsTitle as string;
  const contactInfo = siteBasicInfoData?.data?.contactUs as string;
  const locationImage = siteBasicInfoData?.data?.contactUsPicture as string;
  const locationMap = siteBasicInfoData?.data?.contactUsEmbeddedMap as string;

  const allowedContent = siteBasicInfoData?.data
    ?.contactUsOptionsToShow as string[];

  const isMapAllowed: boolean =
    allowedContent && (allowedContent.includes('map') as boolean);
  const isImageAllowed: boolean =
    allowedContent && (allowedContent.includes('image') as boolean);

  return (
    <Box className="contactus-box"
      sx={{ background: '#F8F8F8' }}
      padding={size == 'sm' || size == 'xs' ? '' : ''}
    >
      <Grid className='custom-container'>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            paddingRight={3}
            py={'10px'}
            display="flex"
            flexDirection="column"
            className="contact-us-text"
            ref={leftContainerRef}
          >
            <Box marginBottom={size == 'sm' || size == 'xs' ? '22px' : 3.5}>
              <RenderedContent>{title}</RenderedContent>
            </Box>
            <RenderedContent>{contactInfo}</RenderedContent>
          </Grid>

          {locationImage && isImageAllowed && !isMapAllowed && (
            <Grid item xs={12} md={6} className='contactleft-img'>
              <Box
                height={leftContainerHeight}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // minHeight: '100%',
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
                  borderRadius: '10px',
                }}
              >
                <Image
                  showLoading
                  duration={100}
                  style={{ padding: '10px' }}
                  src={locationImage}
                  fit="cover"
                  onLoad={handleImageLoad}
                />
              </Box>
            </Grid>
          )}

          {locationMap && isMapAllowed && !isImageAllowed && (
            <Grid item xs={12} md={6}>
              <RenderedContent>{locationMap}</RenderedContent>
            </Grid>
          )}

          {locationMap && locationImage && isMapAllowed && isImageAllowed && (
            <Grid item xs={12} md={12} marginTop={3}>
              <RenderedContent>{locationMap}</RenderedContent>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactSection;
