import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  ArticleOutlined,
  LocationOn,
  People,
  Event,
  PeopleOutline,
} from '@mui/icons-material';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';

// custom tab
import VenueTab from '../Tabs/VenueTab';
import AgendaTab from '../Tabs/AgendaTab';
import DetailsTab from '../Tabs/DetailsTab';
import FacultyTab from '../Tabs/FacultyTab';
import OrganisersTab from '../Tabs/OrganisersTab';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
  IEventDay,
  IFaculty,
  ILocation,
  IVenue,
} from '../api/interfaces/EventFullDetailsInterface';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  setAgendaLocation,
  setDetailsLocation,
  setFacultyLocation,
  setOrganizersLocation,
  setVenueLocation,
} from '../redux/tabSlice';
import RenderedContent from '../components/RenderedContent';

interface ITabs {
  label: string;
  startIcon: any;
  endIcon: any;
  component: React.ReactNode;
  isEmpty: boolean;
  updateLocation?: any;
}

const CustomTab: React.FC<any> = (props) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (locationIndex: number) => {
    handleLocation(tabIndex, locationIndex);
    handleClose();
  };

  const {
    startIcon,
    endIcon,
    label,
    location,
    tabIndex,
    isEmpty,
    handleLocation,
    selected,
    selectedLoc,
    selectedTab,
  } = props;

  const isDropdownActive: boolean = tabIndex === 4 ? false :
    (location?.length as number) > 1 && (tabIndex !== 0) && !isEmpty;

  return (
    <Box {...props}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color={selected ? 'primary' : 'info'}
      >
        {startIcon} &nbsp;
        <Typography
          fontWeight={'bold'}
          fontSize={'20px'}
          fontFamily="Red Hat Display"
        >
          {label}
        </Typography>
        {isDropdownActive && endIcon}
      </Button>
      {isDropdownActive && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {location?.map((l: string, i: number) => (
            <MenuItem
              key={i}
              onClick={() => handleSelect(i)}
              sx={{ minWidth: '150px', height: '40px' }}
              {...(selectedTab === tabIndex && { selected: selectedLoc === i })}
            >
              <Typography
                fontFamily="Red Hat Display"
                fontSize={'16px'}
                lineHeight={'20px'}
              >
                {l}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

const EventTabSection: React.FC = () => {
  const [value, setValue] = useState<number>(0);
  const [isEmptyTab, setIsEmptyTab] = useState<boolean>(false);
  const [tabs, setTabs] = useState<ITabs[]>([]);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<string[]>([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventID = searchParams.get('id') as string;
  const tabID = searchParams.get('tab') as string;
  const locationID = searchParams.get('location') as string;

  React.useEffect(() => {
    setValue(Number(tabID));
    tabID !== null &&
      tabs.length &&
      updateTabLocation(Number(tabID), Number(locationID));
  }, [tabID, locationID, tabs]);

  const { eventFullDetailsData, eventFullDetailsLoading } = useSelector(
    (state: RootState) => state.api
  );

  const emptyTabMessage = eventFullDetailsData?.data
    ?.popup_message_for_empty_tabs as string;

  React.useEffect(() => {
    if (isOpenDialog) {
      const timerId = setTimeout(() => {
        handleCloseDialog();
        setIsOpenDialog(false);
      }, 6000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [isOpenDialog]);

  React.useEffect(() => {
    const locations = eventFullDetailsData?.data?.locations as ILocation[];

    const faculties = (locations &&
      locations[0] &&
      locations[0]?.faculties) as IFaculty[]; // faculties
    const agendas = (locations &&
      locations[0] &&
      locations[0]?.agendas) as IEventDay[]; //  agendas tab
    const venues = (locations &&
      locations[0] &&
      locations[0]?.venues) as IVenue[]; // vanue tab
    // In order to remove location options from organization tab
    const organizers = eventFullDetailsData?.data?.organizers; // organizers

    setLocationList(locations?.map((l) => l.location_name));

    setTabs([
      {
        label: 'Details',
        startIcon: <ArticleOutlined />,
        endIcon: <KeyboardArrowDownIcon />,
        component: <DetailsTab />,
        isEmpty: false,
        updateLocation: setDetailsLocation,
      },
      {
        label: 'Faculty',
        startIcon: <People />,
        endIcon: <KeyboardArrowDownIcon />,
        component: <FacultyTab />,
        isEmpty: !faculties?.length,
        updateLocation: setFacultyLocation,
      },
      {
        label: 'Agenda',
        startIcon: <Event />,
        endIcon: <KeyboardArrowDownIcon />,
        component: <AgendaTab />,
        isEmpty: !agendas?.length,
        updateLocation: setAgendaLocation,
      },
      {
        label: 'Venue',
        startIcon: <LocationOn />,
        endIcon: <KeyboardArrowDownIcon />,
        component: <VenueTab />,
        isEmpty: !venues?.length,
        updateLocation: setVenueLocation,
      },
      {
        label: 'Organizers',
        startIcon: <PeopleOutline />,
        endIcon: <KeyboardArrowDownIcon />,
        component: <OrganisersTab />,
        isEmpty: false, //!organizers?.length,
        updateLocation: setOrganizersLocation,
      },
    ]);
  }, [eventFullDetailsData]);

  const handleLocation = (tabIndex: number, locationIndex: number) => {
    navigateTo(tabIndex, locationIndex);
    setValue(tabIndex);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const isEmptyTab = tabs[newValue]?.isEmpty;
    console.log(locationList?.length, newValue);
    if (isEmptyTab) {
      setIsEmptyTab(true);
      setIsOpenDialog(true);
    } else {
      if (locationList?.length <= 1 || newValue === 0 || newValue === 4) {
        navigateTo(newValue);
        setValue(newValue);
      }
    }
  };

  const navigateTo = (tabIndex: number, locationIndex?: number) => {
    let params: any;
    if (locationIndex !== undefined) {
      params = {
        id: `${eventID}`,
        tab: `${tabIndex}`,
        location: `${locationIndex}`,
      };
    } else {
      params = {
        id: `${eventID}`,
        tab: `${tabIndex}`,
      };
    }

    updateTabLocation(tabIndex, locationIndex);

    navigate({
      pathname: '/event',
      search: `?${createSearchParams(params)}`,
    });
  };

  const handleCloseDialog = () => {
    setIsEmptyTab(false);
  };

  const updateTabLocation = (tabIndex: number, locationIndex?: number) => {
    // update globle location id
    const locationID = locationIndex ? locationIndex : 0;
    dispatch(tabs[tabIndex].updateLocation(locationID));
  };

  return (
    // sx={{
    //   background: 'linear-gradient(to bottom, white 140px, #F8F8F8 60px)',
    // }}
    <Box

    >
      <Container disableGutters fixed>
        <Box sx={{ width: '100%' }}>
          <Box className="tab-list-box" sx={{ display: 'flex', justifyContent: 'center' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                children: <span></span>,
                style: { height: 3 },
              }}
            >
              {tabs?.map((tab, index) => (
                <Tab
                  component={(props) => (
                    <CustomTab
                      {...props}
                      tabIndex={index}
                      selected={value === index}
                      handleLocation={handleLocation}
                      location={locationList}
                      selectedLoc={parseInt(locationID)}
                      selectedTab={parseInt(tabID)}
                      startIcon={tab.startIcon}
                      endIcon={tab.endIcon}
                      label={tab.label}
                      isEmpty={tab.isEmpty}
                    />
                  )}
                  key={index}
                  label={tab.label}
                  icon={tab.startIcon}
                  iconPosition="start"
                  style={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    marginLeft: '6px', // Adjust the spacing between tabs
                    marginBottom: '-10px',
                  }}
                />
                // <CustomTab key={index} />
              ))}
            </Tabs>
          </Box>
          {tabs?.map(({ component }, index) => (
            <Typography
              key={index}
              component="div"
              role="tabpanel"
              sx={{ p: 3 }}
              hidden={value !== index}
            >
              {component}
            </Typography>
          ))}
        </Box>

        {/* dialog box */}
        <Dialog open={isEmptyTab && !!emptyTabMessage} onClose={handleCloseDialog}>
          <DialogTitle sx={{ paddingTop: '0.5rem' }}>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: -8,
                width: '5px',
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: '12px', paddingBottom: '4px' }}>
            <DialogContentText m={0}>
              <RenderedContent>{emptyTabMessage}</RenderedContent>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  );
};

export default EventTabSection;
