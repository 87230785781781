import React from 'react';
import { Box, Card, Grid, Typography, IconButton, Button } from '@mui/material';
import { SxProps } from '@mui/system';
import Image from 'mui-image';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EllipsisText from './EllipsisText ';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface EventCardProps {
  id: number;
  imageSrc: string;
  topBorderColor: string;
  date: Date;
  title: string;
  description: string;
  sx?: SxProps;
}

const EventCard: React.FC<EventCardProps> = ({
  id,
  topBorderColor,
  imageSrc,
  title,
  date,
  description,
  sx,
}) => {
  const navigate = useNavigate();

  const handleReadMore = (eventID: number) => {
    const params = { id: `${eventID}` };
    navigate({
      pathname: '/event',
      search: `?${createSearchParams(params)}`,
    });
  };

  return (
    <Box>
      <Box maxWidth="25.375rem" sx={{ ...sx }}>
        <Card variant="outlined">
          <Box height="0.25rem" bgcolor={topBorderColor}></Box>
          <Box
            minHeight={'11rem'}
            onClick={(e) => handleReadMore(id)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              cursor: 'default',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Image
              duration={600}
              src={imageSrc}
              style={{ width: '100%' }}
              showLoading
              fit="cover"
            />
          </Box>
          <Box p="1.5rem">
            <Typography mb="0.4rem" fontSize={'0.75rem'} lineHeight={'0.5'}>
              {date.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
            </Typography>

            <EllipsisText
              text={title}
              lineCount={1}
              sx={{ fontWeight: 'bold', marginBottom: '0.2rem' }}
            />

            <EllipsisText text={description} lineCount={3} />
          </Box>
          <Card variant="outlined">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              py={'1rem'}
              px={'1.5rem'}
            >
              <Button
                component={IconButton}
                color="primary"
                onClick={(e) => handleReadMore(id)}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexGrow: 1,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography color="primary">Read More..</Typography>
              </Button>
              <IconButton
                color="primary"
                sx={{ margin: '-8px' }}
                onClick={(e) => handleReadMore(id)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </Card>
        </Card>
      </Box>
    </Box>
  );
};

export default EventCard;
