import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import theme from '../theme/basic';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Header from '../components/Header';
import EventCard from '../components/EventCard';
import { stripHtml } from '../util/html';
import { fetchAllEventsData } from '../redux/apiSlice';

const themeColor = '#FDAF31';

const EventsPage: React.FC = () => {
  const dispatch = useDispatch();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { allEventsData, allEventsLoading } = useSelector(
    (state: RootState) => state.api
  );

  React.useEffect(() => {
    !allEventsData && dispatch(fetchAllEventsData());
  }, []);

  return (
    <Box className="event-box">
    <Box className='custom-container'
      style={{
        overflowX: 'clip',
        overflowY: 'initial',
      }}
    >
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Header
            title={'Recent Events'}
            sx={{ marginBottom: '1.2rem' }}
            leftBorderColor={themeColor}
          />
        </Grid>
        <Grid container>
          {(allEventsData ? allEventsData : []).map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              sx={{
                display: isMobile ? 'flex' : 'block',
                justifyContent: isMobile ? 'center' : 'initial',
              }}
              // margin={'auto'}
              p={1}
            >
              <EventCard
                id={item?.id}
                key={index}
                sx={{ marginBottom: '1.375rem' }}
                imageSrc={item.featured_image ? item.featured_image : ''}
                topBorderColor={themeColor}
                date={new Date(item?.event_date || item?.date_gmt)}
                title={item?.title?.rendered}
                description={stripHtml(item?.excerpt?.rendered || item?.content?.rendered)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
    </Box>
  );
};

export default EventsPage;
