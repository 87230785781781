import React from 'react';
import AgendaScheduleItem from '../components/AgendaScheduleItem';
import faculty_member from '../assets/images/faculty_member.png';
import TabSelector from '../components/TabSelector';
import { Box, Grid, Typography } from '@mui/material';
import ButtonTabPanel from '../components/ButtonTabPanel';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
  IEventDay,
  IFaculty,
} from '../api/interfaces/EventFullDetailsInterface';
import RenderedContent from '../components/RenderedContent';
import { formatDateEN_US } from '../util/date';

const AgendaTab: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const { eventFullDetailsData, eventFullDetailsLoading } = useSelector(
    (state: RootState) => state.api
  );

  const locations = eventFullDetailsData?.data?.locations;

  const { agenda } = useSelector((state: RootState) => state.tabLocation);

  const agendas = (locations &&
    locations[agenda] &&
    locations[agenda]?.agendas) as IEventDay[];

  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <Box className="agenda-left-sticky">
        <TabSelector value={value} onChange={setValue} tabs={agendas}>
          {agendas?.map(({ day, event_day, event_date }, index) => (
            <Box key={index} textAlign={'left'}>
              <Typography
                fontWeight={'bold'}
                fontSize={'1.2rem'}
                textTransform={'none'}
                lineHeight={1.5}
                textAlign={'left'}
              >
                {day}
              </Typography>
              <Typography
                fontSize={'0.8rem'}
                textTransform={'none'}
                fontWeight={'regular'}
                textAlign={'left'}
                lineHeight={1.5}
              >
                {event_day}
              </Typography>
              <Typography
                fontSize={'0.8rem'}
                textTransform={'none'}
                textAlign={'left'}
                fontWeight={'regular'}
                lineHeight={1.5}
              >
                {formatDateEN_US(event_date)}
              </Typography>
            </Box>
          ))}          
        </TabSelector>
        </Box>
      </Grid>
      <Grid item xs={0} md={0.2}></Grid>
      <Grid item xs={12} md={9.5}>
        {agendas?.map(({ agenda }, index) => (
          <ButtonTabPanel key={index} value={value} index={index}>
            {agenda?.map((event, index) => (
              <AgendaScheduleItem key={index} event={event} />
            ))}
          </ButtonTabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

export default AgendaTab;
