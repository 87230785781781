import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Header from '../components/Header';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from '../components/RenderedContent';

interface AboutSectionProps {
  bgColor: string;
  description: string;
  title?: string;
  color?: string;
  children?: React.ReactNode;
}

type RequiredOne<T, Keys extends keyof T = keyof T> = Keys extends keyof T
  ? Required<Pick<T, Keys>> & Partial<Omit<T, Keys>>
  : never;

type PropsWithEitherTitleOrChildren = RequiredOne<
  AboutSectionProps,
  'title' | 'children'
>;

const AboutSection: React.FC<PropsWithEitherTitleOrChildren> = ({
  title,
  children,
  bgColor,
  description,
  color,
}) => {
  const { size } = useSelector((state: RootState) => state.mquery);
  return (
    <Box
      bgcolor={bgColor} className="about-box"      
    >
      <Box className="custom-container">
        <Box>
          <Header
            title={title}
            sx={{ marginBottom: '1.2rem' }}
            leftBorderColor={'secondary.main'}
          >
            {children}
          </Header>
          {/* <Typography color={color}>{description}</Typography> */}
          <RenderedContent>{description}</RenderedContent>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutSection;
