import { Grid, Typography } from '@mui/material';
import React from 'react';

interface LogoTextProps {
  label: string;
  size: number;
  fontWeight?: number;
  letterSpacing?: number;
  weight?: string;
  colors?: string[];
}

const LogoText: React.FC<LogoTextProps> = ({
  label = 'firstPart,secondPart',
  size = 2,
  weight,
  letterSpacing,
  colors = ['primary', 'secondary'],
}) => {
  const textParts = label.split(',');
  return (
    <Grid container direction="row" justifyContent={'center'}>
      {textParts.map((item, index) => (
        <Typography
          key={index}
          color={colors[index]}
          fontSize={`${size}rem`}
          fontWeight={weight}
          letterSpacing={letterSpacing}
          fontFamily={'DM Serif Display'}
        >
          {item}
        </Typography>
      ))}
    </Grid>
  );
};

export default LogoText;
