import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface TabLocationState {
  // location: string;
  details: number;
  faculty: number;
  agenda: number;
  venue: number;
  organizers: number;
}

const initialState: TabLocationState = {
  details: 0,
  faculty: 0,
  agenda: 0,
  venue: 0,
  organizers: 0,
};

export const tabLocationSlice = createSlice({
  name: 'tabLocation',
  initialState,
  reducers: {
    setDetailsLocation: (state, action: PayloadAction<number>) => {
      state.details = action.payload;
    },
    setFacultyLocation: (state, action: PayloadAction<number>) => {
      state.faculty = action.payload;
    },
    setAgendaLocation: (state, action: PayloadAction<number>) => {
      state.agenda = action.payload;
    },
    setVenueLocation: (state, action: PayloadAction<number>) => {
      state.venue = action.payload;
    },
    setOrganizersLocation: (state, action: PayloadAction<number>) => {
      state.organizers = action.payload;
    },
  },
});

export const {
  setDetailsLocation,
  setFacultyLocation,
  setAgendaLocation,
  setVenueLocation,
  setOrganizersLocation,
} = tabLocationSlice.actions;

export default tabLocationSlice.reducer;
