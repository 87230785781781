import React from 'react';
import { setHeroImageLoaded } from '../redux/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface ImageContainer {
  src: string | undefined;
  sx: any;
  children: React.ReactNode;
}

const ImageContainer: React.FC<ImageContainer> = ({ src, sx, children }) => {
  const dispatch = useDispatch();

  const { heroImageLoaded } = useSelector((state: RootState) => state.api);

  const handleImageLoad = () => {
    dispatch(setHeroImageLoaded(true));
  };

  return (
    <div
      style={{
        position: 'relative',
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ...sx,
      }}
    >
      {!heroImageLoaded && (
        <img
          src={src}
          alt="Image"
          style={{ display: 'none' }}
          onLoad={handleImageLoad}
        />
      )}
      {children}
    </div>
  );
};

export default ImageContainer;
