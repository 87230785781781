import React, { useRef } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  styled,
  useMediaQuery,
} from '@mui/material';
import theme from '../theme/basic';
import { fetchGalleryData } from '../redux/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Header from '../components/Header';

const imageStyle = {
  transition: 'transform 0.5s',
  cursor: 'pointer',
};

const themeColor = '#FDAF31';

const GalleryPage: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    dispatch(fetchGalleryData());
  }, []);

  const { galleryData, galleryLoading } = useSelector(
    (state: RootState) => state.api
  );

  // const itemData = galleryData

  const handleImageHover = (event: React.MouseEvent<HTMLDivElement>) => {
    // const imageElement = event.currentTarget.querySelector('img');
    // const divElement = event.currentTarget.querySelector('div');
    // if (imageElement) {
    //   imageElement.style.transformOrigin = 'center';
    //   imageElement.style.transform = 'scale(1.2)';
    //   imageElement.style.zIndex = '2';
    //   imageElement.style.position = 'absolute';
    //   imageElement.style.top = '16px';
    //   imageElement.style.left = '16px';
    // }
  };

  const handleImageHoverOut = (event: React.MouseEvent<HTMLDivElement>) => {
    // const imageElement = event.currentTarget.querySelector('img');
    // if (imageElement) {
    //   imageElement.style.transform = 'scale(1) ';
    //   imageElement.style.zIndex = '1';
    //   imageElement.style.position = 'relative';
    //   imageElement.style.transition = 'all 0.2s';
    //   imageElement.style.top = '0px';
    //   imageElement.style.left = '0px';
    // }
  };

  const handleMobile = (event: React.MouseEvent<HTMLDivElement>) => {
    return 0;
  };

  const title = galleryData?.data?.[0]?.title as string;
  const pictures = galleryData?.data?.[0]?.pictures?.[0] as string[];

  return (
    <Box className="gallery-box">
    <Box      
      className='custom-container'
      style={{
        overflowX: 'clip',
        overflowY: 'initial',
      }}
    >
      <Header 
        title={title}
        sx={{ marginBottom: '1.2rem' }}
        leftBorderColor={themeColor}
      />
      <Box display={'flex'} justifyContent={'center'} padding={'0rem'}>
        <Grid container spacing={2} my={3} >
          {pictures?.map((image, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              position={'relative'}
            >
              <Card
                onMouseEnter={isMobile ? handleMobile : handleImageHover}
                onMouseLeave={isMobile ? handleMobile : handleImageHoverOut}
                className='image-cards'
              >
                <CardMedia style={imageStyle} component="img" image={image} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
    </Box>
  );
};

export default GalleryPage;
