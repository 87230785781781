import React from 'react';
import AboutSection from '../sections/AboutSection';
import { Box, Grid, Typography } from '@mui/material';
import LogoText from '../components/LogoText';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';

const AboutPage: React.FC = () => {
  const { siteBasicInfoData, heroImageLoaded } = useSelector(
    (state: RootState) => state.api
  );

  const aboutMediquestEvents = siteBasicInfoData?.data?.aboutMediquestEvents;
  const aboutMediquest = siteBasicInfoData?.data?.aboutMediquest;

  return (
    <>
      <Box className="span-text-to-balck">
        <AboutSection description={aboutMediquestEvents}>
          <Grid container direction="row" justifyContent="center">
            <Typography
              color={'primary'}
              fontSize={'1.625rem'}
              fontWeight={'bold'}
            >
              About{' '}
            </Typography>
            <Grid item>
              <LogoText
                label="&nbsp;Medi,quest&nbsp;"
                size={1.625}
                weight="bold"
                colors={['primary', 'secondary']}
              />
            </Grid>
            <Typography
              color={'primary'}
              fontSize={'1.625rem'}
              fontWeight={'bold'}
            >
              Events
            </Typography>
          </Grid>
        </AboutSection>
      </Box>
      <Box marginTop={'-4.75rem'}>
        <AboutSection description={aboutMediquest}>
          <Grid container direction="row" justifyContent="center">
            <Typography
              color={'primary.main'}
              fontSize={'1.625rem'}
              fontWeight={'bold'}
            >
              About
            </Typography>
            <Grid item>
              <LogoText
                label="&nbsp;Medi,quest"
                size={1.625}
                weight="bold"
                colors={['primary', 'secondary']}
              />
            </Grid>
          </Grid>
        </AboutSection>
      </Box>
    </>
  );
};

export default AboutPage;
