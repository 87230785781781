import React from 'react';
import EventCard from '../components/EventCard';

import { Box, Button, Container, Grid, useMediaQuery } from '@mui/material';
import Header from '../components/Header';
import theme from '../theme/basic';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { stripHtml } from '../util/html';
import { useNavigate } from 'react-router-dom';
import { IPastEvent } from '../api/interfaces/PastEventInterface';
import { IEvent } from '../api/interfaces/AllEventInterface';
import { EventType } from '../api/interfaces/CommonInterfaces';

interface EventListSectionProps {
  title: string;
  themeColor: string;
  events: EventType[] | undefined;
  eventsLoading?: boolean;
  route: string | null;
}

const EventListSection: React.FC<EventListSectionProps> = ({
  title,
  themeColor,
  events,
  eventsLoading,
  route,
}) => {
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { size } = useSelector((state: RootState) => state.mquery);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/events`);
  };

  return (
    <Box className="event-box" padding={size == 'sm' || size == 'xs' ? '' : ''}>
      <Box className="custom-container"        
        style={{
          overflowX: 'clip',
          overflowY: 'initial',
        }}
      >
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"            
          >
            <Header
              title={title}
              sx={{ marginBottom: '1.2rem' }}
              leftBorderColor={themeColor}
              controllers={
                <Button variant="outlined" onClick={handleClick}>
                  View All
                </Button>
              }
            />
          </Grid>

          <Grid container>
            {events?.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  display: isMobile ? 'flex' : 'block',
                  justifyContent: isMobile ? 'center' : 'initial',
                }}
                // margin={'auto'}
                p={1}
              >
                <EventCard
                  id={item?.id}
                  sx={{ marginBottom: '1.375rem' }}
                  imageSrc={item.image ? item.image : ''}
                  topBorderColor={themeColor}
                  date={new Date(item?.date)}
                  title={item?.title}
                  description={item?.description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default EventListSection;
