import { Box, Button, Typography } from '@mui/material';
import React from 'react';

interface ButtonTabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const ButtonTabPanel: React.FC<ButtonTabPanelProps> = ({
  children,
  value,
  index,
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {children}
    </Box>
  );
};

export default ButtonTabPanel;
