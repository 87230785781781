import React, { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// pages
import HomePage from '../pages/HomePage';
import EventPage from '../pages/EventPage';
import GalleryPage from '../pages/GalleryPage';
import AboutPage from '../pages/AboutPage';
import ContactPage from '../pages/ContactPage';
import EventsPage from '../pages/EventsPage';
import ExplorePage from '../pages/ExplorePage';
import InvalidRoute from '../components/InvalidRoute';
import RenderedPage from '../pages/RenderedPage';
import { footerRoutes } from '../constants/footer';

const PublicRoutes: React.FC = () => {
  // for scroll top when change or refresh routes
  const location = useLocation();
  const currentPath = location.pathname;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPath]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/event" element={<EventPage />} /> {/* query params added */}
      <Route path="/events" element={<EventsPage />} />
      <Route path="/explore" element={<ExplorePage />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      {footerRoutes.map((footerItem, index) => (
        <Route key={index} path={footerItem.route} element={<RenderedPage />} />
      ))}
      <Route path="*" element={<InvalidRoute />} />
    </Routes>
  );
};

export default PublicRoutes;
