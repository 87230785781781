import React, { useState } from 'react';
import Image from 'mui-image';
import { Grid, useMediaQuery } from '@mui/material';
import QuarterCircle from './QuarterCircle';
import theme from '../theme/basic';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const DetailsImageSet: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const { eventFullDetailsData, eventFullDetailsLoading, error } = useSelector(
    (state: RootState) => state.api
  );

  const [imageSrcList, setImageSrcList] = React.useState<string[]>(
    Array(4).fill('')
  );

  React.useEffect(() => {
    const imageList: string[] | undefined =
      eventFullDetailsData?.data?.event_galleries;
    const updatedImageSrcList = imageSrcList.map(
      (_, index) => imageList?.[index] || ''
    );
    setImageSrcList((val) => updatedImageSrcList);
  }, [eventFullDetailsData]);

  const space = 1;

  return (
    <>
        {imageSrcList.filter(Boolean).length ? (<Grid container spacing={space}>
          <Grid item sm={5} md={5}>
            <Grid container spacing={space}>
              {!isMobile && (
                <Grid
                  item
                  sm={12}
                  display={'flex'}
                  justifyContent={'flex-end'}
                  mt={2}
                >
                  <QuarterCircle bcolor="primary.main" angle={90} size={2.5} />
                </Grid>
              )}
              <Grid item md={12}>
                <img
                  src={imageSrcList?.[0] || ''}
                  style={{ objectFit: 'cover', width: '100%' }}
                />
              </Grid>
              <Grid item md={12}>
                <Grid container spacing={space}>
                  {!isMobile && (
                    <Grid
                      item
                      sm={4}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <QuarterCircle
                        bcolor="secondary.main"
                        angle={180}
                        size={1.8}
                        transformOrigin="middle"
                      />
                    </Grid>
                  )}
                  <Grid item sm={8}>
                    <img
                      src={imageSrcList?.[1] || ''}
                      style={{ objectFit: 'cover', width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={7} md={7}>
            <Grid container spacing={space}>
              <Grid item>
                <img
                  src={imageSrcList?.[2] || ''}
                  style={{ objectFit: 'cover', width: '100%' }}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={space}>
                  <Grid item sm={9.5}>
                    <img
                      src={imageSrcList?.[3] || ''}
                      style={{ objectFit: 'cover', width: '100%' }}
                    />
                  </Grid>
                  {!isMobile && (
                    <Grid item sm={2.5}>
                      <QuarterCircle
                        bcolor="secondary.main"
                        angle={-90}
                        size={2.5}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>) : null}
    </>
  );
};

export default DetailsImageSet;
