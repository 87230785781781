import React, { useState } from 'react';
import Image from 'mui-image';
import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Link, useNavigate } from 'react-router-dom';
import RenderedContent from '../components/RenderedContent';
import theme from '../theme/basic';

const EventTicketSection: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { size } = useSelector((state: RootState) => state.mquery);
  const { eventFullDetailsData, eventFullDetailsLoading, error } = useSelector(
    (state: RootState) => state.api
  );
  const [imageLoaded, setImageLoaded] = useState<boolean>(true);

  const handleImageError = (error: any) => {
    setImageLoaded(false);
  };

  const title: string = eventFullDetailsData?.data?.title as string;
  const eventLogo: string = eventFullDetailsData?.data
    ?.heading_right_logo as string;
  const eventDate: string = eventFullDetailsData?.data?.event_date as string;
  const ticketImage: string = eventFullDetailsData?.data
    ?.banner_image as string;
  const registrationUrl: string = eventFullDetailsData?.data
    ?.registration_link as string;
  const headingRightLogoText: string = eventFullDetailsData?.data
    ?.heading_right_logo_text as string;

  return (
    <Container disableGutters fixed>
      <Grid container direction={'column'} justifyContent={'space-between'}>
        <Grid item flexGrow={1}>
          <Box className="event-heading-top" display={'flex'} justifyContent={'space-between'}>
            <Typography
              fontSize={size == 'sm' || size == 'xs' ? '21px' : '40px'}
              color={'primary'}
              fontWeight={'bold'}
              lineHeight={size == 'sm' || size == 'xs' ? 1.3 : 1}
              letterSpacing={size == 'sm' || size == 'xs' ? '0.05em' : ''}
            >
              {title}
            </Typography>
            <Box display={'flex'} justifyContent={'flex-end'} className="event-heading-right">
              {imageLoaded && (
                <img
                  // duration={600}
                  src={eventLogo}
                  className='imglogoes'
                  height={''}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'inherit',
                    objectFit: 'contain',
                  }}
                  width={200}
                  // fit="contain"
                  onError={handleImageError}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          flexGrow={1}
          margin={size == 'sm' || size == 'xs' ? '3px' : '10px'}
        >
          <Grid
            container
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            // alignContent={'flex-start'}
          >
            <RenderedContent>
              <Typography>{eventDate}</Typography>
            </RenderedContent>

            <Typography fontSize={'20px'} textAlign={'right'}>
              {headingRightLogoText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box mb={'2rem'} marginTop={'25px'}>
        <Image duration={600} src={ticketImage} />
      </Box>

      <Box
        display={'flex'}
        justifyContent={'center'}
        marginBottom={isMobile ? '-60px' : '-100px'}
      >
        <Button
          variant="contained"
          color="secondary"
          disabled={!registrationUrl?.trim()}
          sx={{
            color: 'white',
            '&:hover': {
              backgroundColor: 'secondary.main',
            },
          }}
          to={registrationUrl}
          component={Link}
        >
          Register Now
        </Button>
      </Box>
    </Container>
  );
};

export default EventTicketSection;
