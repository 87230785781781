import React, { ReactNode } from 'react';
import { isHTML } from '../util/html';
import { Skeleton } from '@mui/material';

interface RenderedContentProps {
  children: any;
}

const getChild: any = (content: any) => {
  const textContent = React.Children.map(content, (child) => {
    return child;
  });

  if (textContent.length > 0) {
    return textContent[0]?.props?.children;
  } else {
    return '';
  }
};

const RenderedContent: React.FC<RenderedContentProps> = ({ children }) => {
  if (children && typeof children === 'string' && isHTML(children)) {
    /**
     *  children = '<p>content</p>'
     */
    return <div dangerouslySetInnerHTML={{ __html: children }} />;
  } else if (
    children &&
    typeof children === 'object' &&
    children.hasOwnProperty('rendered') &&
    typeof children.rendered === 'string' &&
    isHTML(children.rendered)
  ) {
    /**
     *  children = { rendered: '<p>content</p>' }
     */
    return <div dangerouslySetInnerHTML={{ __html: children.rendered }} />;
  } else if (
    children &&
    React.isValidElement(children) &&
    typeof getChild(children) === 'string' &&
    isHTML(getChild(children))
  ) {
    /**
     *  children = <Box>'<p>content</p>'</Box>
     */
    const htmlText = getChild(children);
    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />;
  } else if (
    children &&
    React.isValidElement(children) &&
    typeof getChild(children) === 'string' &&
    !isHTML(getChild(children))
  ) {
    /**
     *  children = <Box>'content'</Box>
     */
    return <div>{children}</div>;
  }

  // return emty skeleton
  return <Skeleton variant="rectangular" width={'100%'} />;
};

export default RenderedContent;
