import React from 'react';
import EventCard from '../components/EventCard';
import Slider from 'react-slick';

import { Box, Container } from '@mui/material';
import { formatDateObj } from '../util/date';

import Header from '../components/Header';
// styles for 'Slider'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { stripHtml } from '../util/html';

interface EventSectionProps {
  title: string;
  themeColor: string;
}

const EventSliderSection: React.FC<EventSectionProps> = ({
  title,
  themeColor,
}) => {
  const { upcomingEventsData, upcomingEventsLoading } = useSelector(
    (state: RootState) => state.api
  );
  const { size } = useSelector((state: RootState) => state.mquery);

  const settings = {
    dots: true,
    infinite: false,
    dotsClass: 'slick-dots',
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ],
    customPaging: (i: number) => (
      <span
        style={{
          backgroundColor: 'black',
          borderRadius: '4px',
          width: '8px',
          height: '8px',
          display: 'inline-block',
          margin: '0 5px',
        }}
      ></span>
    ),
  };

  return (
    <Box>
      {
        ((upcomingEventsData ? upcomingEventsData : []).length > 0) && <Box className="event-box" padding={size == 'sm' || size == 'xs' ? '' : ''}>
        <Box className="custom-container"
          style={{
            overflowX: 'clip',
            overflowY: 'initial',
          }}
        >
          <Box mx={0}>
            <Header
              leftBorderColor={themeColor}
              title={title}
              sx={{ marginLeft: '0.1rem', marginBottom: '1.2rem' }}
            />
            <Slider {...settings}>
              {(upcomingEventsData ? upcomingEventsData : []).map(
                (item, index) => (
                  <Box key={index}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} p={1}>
                      <EventCard
                        id={item?.id}
                        imageSrc={item.featured_image ? item.featured_image : ''}
                        topBorderColor={themeColor}
                        date={formatDateObj(item?.event_date)}
                        title={item?.title?.rendered}
                        description={stripHtml(item?.excerpt?.rendered || item?.content?.rendered)}
                      />
                    </Box>
                  </Box>
                )
              )}
            </Slider>
          </Box>
        </Box>
      </Box>
      }

    </Box>

  );
};

export default EventSliderSection;
