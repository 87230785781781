import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { decrement, increment } from '../redux/counter';
import ContactSection from '../sections/ContactSection';

const ContactPage = () => {
  const count = useSelector((state: RootState) => state.counter.value);
  const dispatch = useDispatch();
  return (
    <>
      {/* <h1>The count is : {count}</h1>
      <button onClick={() => dispatch(increment())}>Increment</button>
      <button onClick={() => dispatch(decrement())}>Decrement</button> */}
      <ContactSection />
    </>
  );
};

export default ContactPage;
