import React from 'react';
import './App.scss';
import theme from './theme/basic';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeaderSection from './sections/HeaderSection';
import FooterSection from './sections/FooterSection';
import PublicRoutes from './routes/PublicRoutes';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchContactData,
  fetchFooterContentoData,
  fetchSiteBasicInfoData,
} from './redux/apiSlice';
import { RootState } from './redux/store';
import { useMediaQuery } from '@mui/material';
import { setScreen } from './redux/mquerySlice';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const { siteBasicInfoData, siteBasicInfoLoading } = useSelector(
    (state: RootState) => state.api
  );
  const primaryColor = siteBasicInfoData?.data?.primaryColor as string;
  const secondaryColor = siteBasicInfoData?.data?.secondaryColor as string;

  const dynamicTheme = React.useMemo(() => {
    return createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          ...theme.palette.primary,
          main: primaryColor || theme.palette.primary.main,
        },
        secondary: {
          ...theme.palette.secondary,
          main: secondaryColor || theme.palette.secondary.main,
        },
      },
    });
  }, [primaryColor, secondaryColor]);

  // screen size detetor
  const size = {
    xs: useMediaQuery(theme.breakpoints.between('xs', 'sm')),
    sm: useMediaQuery(theme.breakpoints.between('sm', 'md')),
    md: useMediaQuery(theme.breakpoints.between('md', 'lg')),
    lg: useMediaQuery(theme.breakpoints.between('lg', 'xl')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  };

  function getKeyTrue(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === true) {
        return key;
      }
    }
    return 'sm';
  }
  React.useEffect(() => {
    const mqueryCode = getKeyTrue(size);
    dispatch(setScreen(mqueryCode));
  }, [size]);

  React.useEffect(() => {
    dispatch(fetchSiteBasicInfoData());
    dispatch(fetchFooterContentoData());
    dispatch(fetchContactData());
  }, []);
  return (
    <>
      <ThemeProvider theme={dynamicTheme}>
        <HeaderSection />
        <PublicRoutes />
        <FooterSection />
      </ThemeProvider>
    </>
  );
};

export default App;
