import React from 'react';
import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import ImageContainer from '../components/ImageContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from '../components/RenderedContent';
import theme from '../theme/basic';
import { useNavigate } from 'react-router-dom';

const commonStyles = {
  position: 'absolute',
  width: '30px',
  height: '35%',
  zIndex: 2,
};
const HeroSection: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { siteBasicInfoData, siteBasicInfoLoading } = useSelector(
    (state: RootState) => state.api
  );

  const handleClick = () => {
    navigate(`/events`);
  };

  const heroImage = siteBasicInfoData?.data?.header_image;
  const headerTitle = siteBasicInfoData?.data?.header_title as string;
  const headerSubTitle = siteBasicInfoData?.data?.header_sub_title;
  const headerText = siteBasicInfoData?.data?.header_text;

  return (
    <>
      <Box className="banner-box" sx={{ position: 'relative' }}>
        {!isMobile && (
          <>
            <Box
              bgcolor="secondary.main"
              sx={{
                ...commonStyles,
                bottom: 0,
                left: 0,
              }}
            />
            <Box
              bgcolor="primary.main"
              sx={{
                ...commonStyles,
                top: 0,
                right: 0,
              }}
            ></Box>
          </>
        )}
        <ImageContainer sx={{ height: '635px' }} src={heroImage}>
          {/* <Container disableGutters fixed> */}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            px={2}
            sx={{ height: '100%', width: '100%' }}
          >
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                marginBottom: '40.5px',
              }}
            >
              <RenderedContent>{headerTitle}</RenderedContent>
            </Box>
            <Box className="banner-content"
              sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: 750,
                marginBottom: '36px',
              }}
            >
              <RenderedContent>{headerSubTitle}</RenderedContent>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: 'white' }}
              onClick={handleClick}
            >
              Explore Events
            </Button>
          </Grid>
          {/* </Container> */}
        </ImageContainer>
      </Box>

      <Grid className='blue-content-box'
        bgcolor="primary.main"        
      >
        <Box className="custom-container">
          <RenderedContent>{headerText}</RenderedContent>
        </Box>
      </Grid>
    </>
  );
};

export default HeroSection;
